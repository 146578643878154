// :cow: Cosmose CONFIDENTIAL :iso:
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SwUpdate } from '@angular/service-worker';
import en_US from '../assets/i18n/en_US.json';

@Component({
  selector: 'kkna-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    private swUpdate: SwUpdate,
  ) {
    translateService.setTranslation('en_US', en_US);
  }

  ngOnInit(): void {
    this.initSwUpdate();

  }

  private initSwUpdate(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        window.location.reload();
      });
    }
  }
}
